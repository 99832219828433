import { cn } from '@/lib/tw'
import { Button } from './ui/button'

export interface NextErrorMessageType {
  error: Error
  reset: () => void
}
interface ErrorMessageType {
  title: string
  message: string
  className?: string
  onRetry?: () => void
}
export const ErrorMessage = (props: ErrorMessageType) => {
  const { title, message, className, onRetry } = props
  return (
    <div className={cn('flex flex-col items-center mt-10 px-4', className)}>
      <img src="/images/error.png" alt="" className="w-auto h-48 mx-auto" />
      <div className=" text-center mt-4 font-semibold text-2xl">{title}</div>
      <div className="text-center mt-1 text-sm">{message}</div>

      {onRetry ? (
        <Button onClick={onRetry} className="mt-6 min-w-[100px]" variant="destructive">
          Retry
        </Button>
      ) : null}
    </div>
  )
}
