'use client'

import { ErrorMessage, type NextErrorMessageType } from '@/components/error-message'

export default function Error({ error, reset }: NextErrorMessageType) {
  return (
    <ErrorMessage
      title="Error getting info"
      message={error.message ?? 'Something went wrong'}
      onRetry={reset}
    />
  )
}
